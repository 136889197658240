@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sidebar-icon {
  fill: #666;
  stroke: #666;
  stroke-width: 0.5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ql-editor {
  height: 150px !important;
}

.ql-editor ul > li::before {
  content: "\2B24" !important;
}

.tooltiprich {
  visibility: hidden;
  margin-bottom: -22px;
  margin-left: 32%;
  background-color: black;
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  width: fit-content;
}

.MuiPaper-root * {
  white-space: pre-line;
}

.component-wrapper {
  padding: 20px 24px;
}

.component-wrapper-x {
  padding-left: 24px;
  padding-right: 24px;
}

.component-wrapper-y {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 1200px) {
  .full_width {
    max-width: 100% !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 780px) {
  .component-wrapper {
    padding: 16px;
  }

  .component-wrapper-x {
    padding-left: 16px;
    padding-right: 16px;
  }

  .component-wrapper-y {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
